<template>
  <base-material-card icon="mdi-clipboard-text" :title="$t('menu.lead.list')" class="px-5 py-3">
    <base-add-button :permissions="permissions" :basePath="basePath" :title="$t('lead.add')">
    </base-add-button>
    <div class="mt-2">{{ $t('lead.viewTotal') }}: {{ meta.total }}</div>
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="primary--text">No.</th>
          <th class="primary--text">
            {{ $t('lead.uuid') }}
          </th>
          <th class="primary--text">
            {{ $t('lead.catalogUuid') }}
          </th>
          <th class="primary--text">
            {{ $t('lead.name') }}
          </th>
          <th class="primary--text">
            {{ $t('lead.createdAt') }}
          </th>
          <th class="text-center primary--text">
            {{ $t('general.openDetail') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in items" :key="`lead-${index}`">
          <td>{{ index + 1 }}</td>
          <td>{{ item.uuid }}</td>
          <td>{{ item.catalog_agent != null ? item.catalog_agent.uuid : '' }}</td>
          <td>{{ item.client_name }}</td>
          <td>{{ format(new Date(item.created_at), 'dd MMMM yyyy HH:mm') }}</td>
          <td>
            <base-detail-button
              :permissions="permissions"
              :basePath="basePath"
              :id="item.uuid"
            ></base-detail-button>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <pagination
      :push-state="false"
      :meta="meta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta"
    />
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';
const Pagination = () => import('@/components/utils/fractal-pagination');

export default {
  mixins: [HelperMixin],
  props: ['items', 'meta', 'permissions'],
  components: {
    Pagination,
  },
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.lead.basePath,
    }),
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
    changeFilter(val) {
      this.$emit('changeFilter', val);
    },
  },
};
</script>
